import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CloudAlert
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTJ2NCIgLz4KICA8cGF0aCBkPSJNMTIgMjBoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNyAxOGguNWExIDEgMCAwIDAgMC05aC0xLjc5QTcgNyAwIDEgMCA3IDE3LjcwOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cloud-alert
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const CloudAlert = createLucideIcon('CloudAlertIcon', [
  ['path', { d: 'M12 12v4', key: 'tww15h' }],
  ['path', { d: 'M12 20h.01', key: 'zekei9' }],
  ['path', { d: 'M17 18h.5a1 1 0 0 0 0-9h-1.79A7 7 0 1 0 7 17.708', key: 'xsb5ju' }],
]);

export default CloudAlert;
